import {createRouter, createWebHistory} from "vue-router";
import HomeSite from "@/components/HomeSite";
import StartScreeen from "@/components/StartScreeen";
import HomeWork from "@/components/HomeWork";
import Question from "@/components/Question";
import Congratulations from "@/components/Congratulations";
import EndScreen from "@/components/EndScreen";

const routes = [
  { path: '/', name: 'Home', component: HomeSite},
  { path: '/start', name: 'Start', component: StartScreeen},
  { path: '/homework', name: 'Homework', component: HomeWork},
  { path: '/questions', name: 'Question', component: Question},
  { path: '/congratulations', name: 'Congratulations', component: Congratulations},
  { path: '/end', name: 'End', component: EndScreen},
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router