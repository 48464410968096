<template>
    <div class="homeSite">
      <div class="bgCover"></div>
      <img src="../assets/objects/RenaissanceAffairsBanner.png" class="renaissanceBanner mx-auto"/>
      <div>
        <h2 class="title-text">
          Official Verification <br> Application Platform (EST. 1997)
        </h2>
        <div class="smaller-title-text">Application for true Blackmore's Night Fan-Certificate</div>
      </div>
      <h3 class="medieval-font">25 years of Blackmore's Night</h3>
      <img src="../assets/cover.jpg" class="cover-image pt-3"/>
      <WoodButton
        class="my-3 mx-auto"
        title="START"
        @click="$router.push('/start')"
      />
    </div>
</template>

<script>
import WoodButton from "@/shared/WoodButton";
export default {
  name: "homeSite",
  components: {WoodButton},
}
</script>

<style scoped>
.homeSite{
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-rows: 3fr 1fr 1fr 4fr 2fr;
  overflow: auto;
}

.renaissanceBanner{
  width: 100%;
  max-width: 750px;
  max-height: 250px;
}
@media only screen and (min-width: 768px) {
  .renaissanceBanner{
    max-width: 1500px;
    max-height: 500px;
  }
}
.cover-image{
  width: 60%;
  justify-self: center;
}
</style>

