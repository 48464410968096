<template>
  <div class="w-100 h-100 d-flex flex-column justify-content-center">
    <div class="bgCover"></div>

    <img
      src="../assets/backgrounds/branchesLeft.png"
      class="h-100"
      style="position: absolute; z-index: -1;"
    />
    <img
      src="../assets/backgrounds/branchesRight.png"
      class="h-100"
      style="position: absolute; z-index: -1; right: 0;"
    />
    <img
      src="../assets/backgrounds/WoodBoard.png"
      class="h-100 w-100"
      style="position: absolute; z-index: -2;"
    />

    <h1 class="title-text w-75 mx-auto">Who formed the Band "Blackmore's Night"?</h1>
    <WoodButton class="mx-auto my-4" title="Bonnie and Clyde" font-size="1.5rem" @click="$router.push('/homework')"/>
    <WoodButton class="mx-auto my-4" title="Minnie and Mickey" font-size="1.5rem" @click="$router.push('/homework')"/>
    <WoodButton class="mx-auto my-4" title="Candice and Ritchie" font-size="1.3rem" @click="$router.push('/congratulations')"/>
    <WoodButton class="mx-auto my-4" title="Eve and Adam" font-size="2rem" @click="$router.push('/homework')"/>

  </div>
</template>

<script>
import WoodButton from "@/shared/WoodButton";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Question",
  components: {WoodButton},
}
</script>

<style scoped>

</style>