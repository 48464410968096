<template>
  <div
    class="WoodButton"
    @click="!disabled && $emit('click')"
  >
    <button
      class="woodBackground"
      :class="disabled ? 'disabled' : ''"
    >
    </button>
    <h1 class="headline" :style="cssEffect">
      <svg
        aria-hidden="true"
        :id="`lockup-headline-mask-${titleWithoutSpaces}`"
        class=""
      >
        <clipPath
          :id="`lockup-headline-mask-path-${titleWithoutSpaces}`"
        >
          <text
            dominant-baseline="middle"
            text-anchor="middle"
            :font-size="fontSize"
            y="35%"
            x="50%"
            dy="0"
          >
            <tspan v-if="!multiline">{{title}}</tspan>
            <tspan
              v-else
              v-for="(line, i) of title.split(' ')"
              :key="line"
              :y="(i)*16+15+'%'"
              x="50%"
              dx="0"
            >
              {{line}}
            </tspan>
          </text>
        </clipPath>
      </svg>
    </h1>
  </div>
</template>

<script>
export default {
  name: "WoodButton",
  emits: ['click'],
  props: {
    title: String,
    disabled: Boolean,
    fontSize: {
      type: String,
      default: '4rem'
    },
    multiline: Boolean,
  },
  data() {
    return {
      cssEffect: {
        'backdrop-filter': 'brightness(1.8)',
        'background': 'linear-gradient(360deg, rgba(255,188,0,0.5) 0%, rgba(255,248,0,0.5) 100%)',
        'clip-path': `url(#lockup-headline-mask-path-${this.title.replace(/\s/g, "")})`,
        'width': 'max-content'
      }
    }
  },
  computed:{
    titleWithoutSpaces(){
      return this.title.replace(/\s/g, "");
    }
  }
}
</script>

<style scoped>
.WoodButton{
  position: relative;
  height: 90px;
  width: min-content;
  cursor: pointer;
}
.woodBackground {
  position: absolute;
  border-radius: 15px;
  border-width: 1px;
  filter: drop-shadow(-5px 5px 15px #000);
  height: 90px;
  width: 100%;
  font-size: 4rem;
  background-image: url("../assets/objects/woodButton.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 110% 110%;
}
.disabled{
  filter: grayscale(0.4);
}

h1 {
  position: relative;
}
.headline {
  width: min-content;
  font-family: sans-serif;
  text-transform: uppercase;
  font-size: 4rem;
  font-weight: bold;
  height: 90px;
}

</style>