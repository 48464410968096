<template>
  <div class="certificateWrapper" style="top:0; left: 0;">
    <img class="certificate" src="../assets/objects/cert.jpg" />
    <div class="nameField" :style="nameSize">
      {{person.firstName}} {{person.surname}}
    </div>
    <div class="cityField" :style="citySize">
      {{person.city}}
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Certificate",
  inject: ['person'],
  computed: {
    nameSize(){
      return `font-size: ${(350 / (this.person.firstName + this.person.surname)?.length*1.4)}px`
    },
    citySize(){
      return `font-size: ${(350 / (this.person.city)?.length*1.4)}px`
    }
  }
}
</script>

<style lang="scss" scoped>
.certificateWrapper{
  position: absolute;
  z-index: -11;
}

.certificate {
  z-index: 10;
}
.nameField{
  position: absolute;
  top: 1025px;
  left: 385px;
  z-index: 11;
  width: 350px;
  height: 50px;
  line-height: 50px;
  font-family: Vinque;
}
.cityField{
  position: absolute;
  top: 1020px;
  left: 895px;
  z-index: 11;
  width: 350px;
  height: 50px;
  line-height: 50px;
  font-family: Vinque;
}

</style>