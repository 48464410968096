<template>
  <div class="d-flex h-100 w-100 justify-content-center">
    <div class="bgCover"></div>
    <div class="w-100 h-100 position-absolute overflow-hidden">
      <img
        src="../assets/backgrounds/branchesLeft.png"
        class="h-100"
        style="position: absolute; z-index: -1; left: -30%;"
      />
      <img
        src="../assets/backgrounds/branchesRight.png"
        class="h-100"
        style="position: absolute; z-index: -1; right: -15%;"
      />
    </div>
    <img
      src="../assets/backgrounds/WoodBoard.png"
      class="h-100 w-100"
      style="position: absolute; z-index: -2;"
    />

    <div class="endScreen">
      <div class="thinFont">Your information has been processed successfully! Thank you for validating your loyalty.</div>
      <div class="thinFont">Your document is now <br> ready for download.</div>

      <WoodButton class="mx-auto" title="Download Official Certificate" multiline font-size="1.5rem" @click="downloadCertificate"/>

      <div class="text-center">
        <div class="thinFont">Or post directly to socials:</div>
        <a @click="shareCertificate"><img class="btn icon" src="../assets/icons/share.svg" /></a>
      </div>

      <WoodButton
        title="Visit Blackmore´s Night"
        class="mx-auto"
        multiline
        font-size="1.3rem"
        @click="redirect"
      />
    </div>

    <Certificate
      style="display: none;"
      ref="certificate"
    />

  </div>
</template>

<script>
import WoodButton from "@/shared/WoodButton";
import html2canvas from "html2canvas";
import Certificate from "@/shared/Certificate";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Endscreen",
  components: {Certificate, WoodButton},
  async mounted(){
    const card = this.$refs.certificate.$el;
    const canvas = await html2canvas(card);
    this.baseImage = canvas.toDataURL("image/png");
  },
  data(){
    return {
      baseImage: {},
      router: this.$router
    }
  },
  methods: {
    redirect(){
      window.open('https://www.blackmoresnight.com/', '_blank').focus();
    },

    async shareCertificate(){
      const card = this.$refs.certificate.$el;
      card.style.display = 'block';
      const canvas = await html2canvas(card);
      card.style.display = 'none';
      const base64image = canvas.toDataURL("image/png");
      const blob = await (await fetch(base64image)).blob();
      const filesArray = [
        new File(
          [blob],
          'animation.png',
          {
            type: blob.type,
            lastModified: new Date().getTime()
          }
        )
      ];
      const shareData = {
        files: filesArray,
      };

      if(navigator.canShare()){
        await navigator.share(shareData);
      }else{
        alert('This device does not support the sharing function. Please download and share then.')
      }
    },

    async downloadCertificate(){
      const card = this.$refs.certificate.$el;
      card.style.display = 'block';
      const canvas = await html2canvas(card);
      card.style.display = 'none';
      const base64image = canvas.toDataURL("image/png");
      const anchor = document.createElement('a');
      anchor.setAttribute("href", base64image);
      anchor.setAttribute("download", "certificate.png");
      anchor.click();
      anchor.remove();
    }
  }
}
</script>

<style lang="scss" scoped>
.endScreen{
  padding-top: 2rem;
  height: 75%;
  width: 70%;
  display: grid;
  grid-template-rows: 3fr 2fr 3fr 3fr 3fr;
  justify-content: center;
  align-self: center;
}

.thinFont{
  line-height: 2rem;
  font-size: larger;
  font-family: FonescaThin;
  color: white;
  text-align: center;
  white-space: normal;
}


.certificate{
  height: 720px;
  width: 1280px;
}

.icon {
  width: 5rem;
  height: 4rem;
}
</style>