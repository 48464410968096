import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import './css/fonts.css';
import './css/backgrounds.css';

import router from "@/router";

const app = createApp(App)
app.config.unwrapInjectedRef = true
app.use(router).mount('#app')
