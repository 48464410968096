<template>
    <div class="inputBackground border border-secondary title-text d-flex align-content-center justify-content-center p-1" @click="valueModel = !valueModel">
      <div class="checkmark">
        {{valueModel ? '✓' : showFalse ? 'X' : ''}}
      </div>
    </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Checkbox",
  emits: ['update:modelValue'],
  props: {
    modelValue: Boolean,
    showFalse: Boolean
  },
  computed: {
    valueModel: {
      get(){return this.modelValue;},
      set(val){ this.$emit('update:modelValue',val); }
    }
  },
}
</script>

<style scoped>
.inputBackground{
  width: 2.5rem;
  min-width: 2.5rem;
  max-width: 2.5rem;
  height: 2.5rem;
  min-height: 2.5rem;
  max-height: 2.5rem;
  border-radius: 8px;
  backdrop-filter: brightness(0.8);
}
.checkmark{
  font-size: 1.4rem;
}
</style>