<template>
  <audio
    ref="audio"
    class="paused"
    :src="music"
    preload
    loop
    volume="0.1"
    autoplay
    id="audio"
  ></audio>
  <div
    class="btn toggle-sound"
    @click="toggleSound()"
  >
    <div class="d-flex justify-content-center align-content-center">
      <img
        src="./assets/objects/playButton.png"
        class="w-100 h-100 rounded border border-dark"
        alt=""
      />
      <img
        :src="pause"
        :class="musicPlayed ? 'opacity-25' : ''"
        class="w-25 h-25 align-self-center position-absolute"
      />
    </div>
  </div>
  <router-view />
<!--  <HomeSite v-if="currentSite === 'Home'" @clicked="currentSite='Start'"/>-->
<!--  <StartScreeen-->
<!--    v-if="currentSite === 'Start'"-->
<!--    @yes="currentSite = 'Question'"-->
<!--    @no="currentSite = 'HomeWork'"-->
<!--  />-->
<!--  <HomeWork v-if="currentSite === 'HomeWork'" @back="currentSite='Home'"/>-->
<!--  <Question v-if="currentSite === 'Question'" @no="currentSite='HomeWork'" @yes="currentSite='Winning'"/>-->
<!--  <Congratulations v-if="currentSite === 'Winning'" @submit="currentSite='Submitting'"/>-->
<!--  <Endscreen v-if="currentSite === 'Submitting'"/>-->
</template>

<script>
// import HomeSite from "@/components/HomeSite";
// import StartScreeen from "@/components/StartScreeen";
// import HomeWork from "@/components/HomeWork";
// import Question from "@/components/Question";
// import Congratulations from "@/components/Congratulations";
import {computed} from 'vue';
// import Endscreen from "@/components/EndScreen";

export default {
  name: 'App',
  components: {
    // Endscreen,
    // Congratulations,
    // Question,
    // HomeWork,
    // StartScreeen,
    // HomeSite
  },
  provide() {
    return {
      person: computed(() => this.person)
    }
  },
  data(){
    return {
      currentSite: 'Home',
      musicPlayed: true,
      person: {
        firstName: '',
        surname: '',
        email: ''
      },
      pause: require('./assets/objects/pause.svg'),
      music: require('./assets/Sound/bgMusic.mp3'),
    }
  },
  methods:{
    toggleSound() {
      let audio = this.$refs.audio;
      if (!this.musicPlayed) {
        audio.play();
        this.musicPlayed = true;
        document.querySelector(".toggle-sound").classList.remove("paused");
      } else {
        audio.pause();
        this.musicPlayed = false;
        document.querySelector(".toggle-sound").classList.add("paused");
      }
    }
  }
}

</script>

<style>
body > div{
  background: linear-gradient(90deg, rgba(0,0,0,0.6) 10%, rgba(255,255,255,0) 30%, rgba(255,255,255,0) 70%, rgba(0,0,0,0.6) 90%);
}

.toggle-sound{
  position: absolute;
  right: 5px;
  top: 5px;
  width: 5rem;
  height: 5rem;
}
@media only screen and (min-width: 768px) {
  .toggle-sound{
    width: 8rem;
    height: 8rem;
  }
}

body{
  overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Vinque";
  src: local("Vinque"),
  url(./assets/Fonts/Vinque/VinqueBase.otf) format('truetype');
}
@font-face {
  font-family: "VinqueThin";
  src: local("VinqueThin"),
  url(./assets/Fonts/Vinque/VinqueThin.otf) format('truetype');
}
@font-face {
  font-family: "Fonesca";
  src: local("Fonesca"),
  url(./assets/Fonts/Fonseca/FonsecaLight.otf) format('truetype');
}
@font-face {
  font-family: "FonescaThin";
  src: local("FonescaThin"),
  url(./assets/Fonts/Fonseca/FonsecaThin.otf) format('truetype');
}
</style>
