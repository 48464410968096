<template>
  <div class="h-100 d-flex justify-content-center">
    <div class="bgCover"></div>
    <div class="homeWorkSite align-self-center">
      <div class="title-text" style="font-size: 4rem">DO YOUR HOMEWORK!</div>
      <div class="title-text">And start by listening to this essential Blackmore's Night Playlist on Spotify:</div>
      <a href="https://open.spotify.com/playlist/0Gs4tEzRKDIgHvTqOVBXAb">
        <img src="../assets/objects/coverHomework.png" class="w-75 mx-5"/>
      </a>
      <div class="d-flex justify-content-center mx-3 my-4">
        <a role="button" class="mx-2" href="https://www.instagram.com/blackmoresnightofficial/"><img class="icon" src="../assets/icons/instagram.png" /></a>
        <a role="button" class="mx-2" href="https://www.facebook.com/blackmoresnightofficial/ "><img class="icon" src="../assets/icons/facebook.png"/></a>
        <a role="button" class="mx-2" href="https://twitter.com/TheRealRitchieB"><img class="icon" src="../assets/icons/rirchieTwitter.png"/></a>
        <a role="button" class="mx-2" href="https://twitter.com/Trucandicenight"><img class="icon" src="../assets/icons/candiceTwitter.png"/></a>
        <a role="button" class="mx-2" href="https://www.youtube.com/user/TrueBlackmoresNight"><img class="icon" src="../assets/icons/youtube.png"/></a>
      </div>
      <WoodButton title="BACK" class="mx-auto mt-2" @click="$router.push('/')"/>
    </div>
  </div>
</template>

<script>
import WoodButton from "@/shared/WoodButton";
export default {
  name: "HomeWork",
  components: {WoodButton},

}
</script>

<style scoped>
.homeWorkSite{
  height: 95%;
  width: 90%;
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-rows: 1fr 3fr 1fr 1fr 3fr 2fr;
  overflow: auto;
}

.coverHomework{
  width: 100%;
}
.icon{
  max-width: 5.5rem;
  max-height: 3.5rem;
}

@media only screen and (min-width: 768px) {
  .coverHomework{
    width: 100%;
    max-width: 500px;
  }
  .icon{
    max-width: 7.5rem;
    max-height: 5.5rem;
  }
}


</style>