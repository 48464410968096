<template>
  <div class="h-100 d-flex justify-content-center">
    <div class="bgCover"></div>
    <img
      src="../assets/backgrounds/WoodBoard.png"
      class="h-100 w-100"
      style="position: absolute; z-index: -2;"
    />
    <div class="startScreen align-self-center">
      <img
        src="../assets/objects/departmentFont.png"
        class="w-100"
      />
      <div>
        <h3 class="medieval-font pb-4">25 years of Blackmore's Night</h3>
        <div class="line-separator border-bottom border-1 w-75 mx-auto mt-2"></div>
        <div class="line-separator border-bottom border-1 w-75 mx-auto mb-2 mt-1"></div>
        <div class="questionFont pt-4">
          Are you Sure you have been a Blackmore's Night fan since the first release of "Shadow of the Moon" in 1997?
        </div>
      </div>

      <WoodButton class="mx-auto my-2" title="YES" @click="$router.push('/questions')"/>
      <WoodButton class="mx-auto my-2" title="NO" @click="$router.push('/homework')"/>
    </div>

  </div>
</template>

<script>
import WoodButton from "@/shared/WoodButton";
export default {
  name: "StartScreeen",
  components: {WoodButton},
}
</script>

<style scoped>
.startScreen{
  width: 70%;
  height: 70%;
  display: grid;
  grid-template-rows: 2fr 3fr 1fr 1fr;
  justify-content: center;
  align-content: center;
}

.questionFont{
  line-height: 2rem;
  font-size: large;
  font-family: FonescaThin !important;
  color: white;
  text-align: center;
  white-space: normal;
}

</style>