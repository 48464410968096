<template>
  <div class="h-100 d-flex justify-content-center">
    <div class="bgCover"></div>
    <img
      src="../assets/objects/bird.png"
      class="h-25"
      style="position: absolute; z-index: -1; left: -10%; top: 5%;"
    />
    <div class="congratulationsScreen align-self-center">
      <div>
        <div class="title-text" style="font-size: 2.5rem">Congratulations!</div>
        <h3 class="title-text">You are now a certified <br> fan from day 1!</h3>
      </div>
      <div class="terms">Please complete the following to obtain your official document:</div>

      <div class="text-center">
        <input class="inputField medieval-font" v-model="person.firstName"/>
        <div class="terms">First Name</div>
      </div>

      <div class="text-center">
        <input class="inputField medieval-font" v-model="person.surname"/>
        <div class="terms">Surname</div>
      </div>
      <div class="text-center">
        <input class="inputField medieval-font" v-model="person.city"/>
        <div class="terms">City</div>
      </div>
      <div class="text-center">
        <input class="inputField medieval-font" v-model="person.email"/>
        <div class="terms ">E-mail address</div>
      </div>

      <div>
        <div class="d-flex w-100 align-items-center">
          <div class="px-3">
            <Checkbox v-model="certification" />
          </div>
          <div class="certify" style="font-size: small"> I certify, under penalty of musical perjury, that I provided or authorized information in my application. I understand all of the information contained in and submitted with, my application, and that all of this information is complete, true, and correct.</div>
        </div>
        <div class="d-flex w-100 align-items-center pt-3">
          <div class="px-3">
            <Checkbox v-model="termsAndConditions" />
          </div>
         <div class="certify"> <a href="https://www.ear-music.net/terms-and-conditions" class="text-white">I ACCEPT THE TERMS AND CONDITIONS</a></div>
        </div>
        <div class="d-flex w-100 align-items-center">
          <div class="px-3">
            <Checkbox v-model="newsletter"/>
          </div>
          <div class="certify" style="font-size: small">I would like to receive the Blackmore’s Night newsletter. I hereby consent to the storage of my contact data and acknowledge the <a href="https://www.ear-music.net/privacy-protection-statement/">privacy policy</a> statement. My consent can be revoked anytime <a href="info@ear-music.net">here.</a>
            You may unsubscribe at any time using the link in our newsletter.</div>
        </div>
      </div>

      <WoodButton
        title="SUBMIT"
        class="mx-auto"
        :disabled="!certification || !termsAndConditions"
        @click="newsletter && sendEmail(); $router.push('/end')"
      />
    </div>
  </div>
</template>

<script>
import WoodButton from "@/shared/WoodButton";
import Checkbox from "@/shared/Checkbox";
import axios from "axios";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Congratulations",
  components: {Checkbox, WoodButton},
  inject: ['person'],
  data(){
    return {
      certification: false,
      termsAndConditions: false,
      newsletter: false
    }
  },
  methods:{
    async sendEmail(){
      await axios.post(
        'https://f250174e.sibforms.com/serve/MUIEAHy9nzxtRIuotWfl3piT-DAaJOAjzW7MccSeU6TWrdBvhh0zFGe9zwBW9-cBW3ErzcGng3P-KS06LdEefM3SJtcK_3ZytvqB8UavBnkTCn56DbzuJ7l2o_iwH9GEJ_ch15ezUPtKc_xnj057oAaaoPLbT7vSmo9vywxTj9Nshg3MB4YHpwzlsE-a_tlu6sTWHLJm8xZlJB9q',
        {
          'VORNAME': this.person.firstName,
          'NACHNAME': this.person.surname,
          'EMAIL': this.person.email,
          "OPT_IN": 1,
          "email_address_check": null,
          "locale": 'de'
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          params: { isAjay: 1 }
        }
      )
    }
  }
}
</script>

<style scoped>
.congratulationsScreen{
  height: 95%;
  width: 90%;
  display: grid;
  grid-template-rows: 2fr 1fr 1fr 1fr 1fr 1fr 2fr 2fr;
  align-items: center;
  justify-content: center;

}

.terms{
  line-height: 2rem;
  font-size: larger;
  font-family: FonescaThin;
  color: white;
  text-align: center;
  white-space: normal;
}

.certify{
  font-family: system-ui;
  color: white;
  font-weight: 300;
}

.inputField{
  border-radius: 15px;
  background: transparent;
  border-width: 1px;
  backdrop-filter: brightness(0.6);
  width: 15rem;
}

</style>